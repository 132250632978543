import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Evaluations({ isWidget, selState }) {
  // AUTH, STATE, NAVIGATION
  const { authobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleAppointment, handleSuspension, handleRework, handleExport } = useHelpers();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [evaluations, setEvaluations] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentNote, setAppointmentNote] = useState(null);
  // FILTER & SEARCH
  const [query, setQuery] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [orderField, setOrderField] = useState('id_commessa');
  const [orderType, setOrderType] = useState('ASC');
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    if (state && state.create_new)
      navigate('/evaluations/add');

    setWait(true);
    axios.post(window.SERVER_URL+'evaluation_statuses/list', { params: { 'light': true } }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setStatuses(res.data.statuses);

      if (selState) {
        console.log('Stato selezionato: '+selState);
        res.data.statuses.forEach((status) => {
          if (status.slug == selState)
            handleChangeSelectedStatus(status.id);
        });
      } else {
        // document.getElementById('query').value = state && state.query ? state.query : query;
        getPage(
          state && state.page ? state.page : page,
          state && state.query ? state.query : query
        );
      }
    });
  }, [globalRefresh, selState]);

  const retrieveEvaluations = (params) => {
    if (params && params.page) {
      axios.post(window.SERVER_URL+'evaluations/list', { params }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setEvaluations(res.data.evaluations);
        setPage(params.page);
        setNumPages(res.data.pages);
        setCount(res.data.count);
        setWait(false);
      });
    }
  }

  const getPage = (i, params) => {
    if (!params)
      var params = {
        page: i,
        search: {
          query: (query && query.length >= 3 ? query : ''),
          stato: selectedStatus ? selectedStatus.value : ''
        },
        order: {
          field: orderField,
          type: orderType
        }
      }
    console.log("PARAMS:");
    console.log(params); 
    retrieveEvaluations(params);
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    setQuery(typed);
    var params = {
      page: 1,
      search: {
        query: (typed && typed.length >= 3 ? typed : ''),
        stato: selectedStatus ? selectedStatus : ''
      },
      order: {
        field: orderField,
        type: orderType
      }
    }
    getPage(1, params);
  }

  const handleChangeSelectedStatus = (status) => {
    console.log("STATUS: "+status);
    setSelectedStatus(status);
    var params = {
      page: 1,
      search: {
        query: (query && query.length >= 3 ? query : ''),
        stato: status ? status : ''
      },
      order: {
        field: orderField,
        type: orderType
      }
    }
    getPage(1, params);
  }

  const handleChangeOrder = (field) => {
    console.log("CHANGED ORDER");
    setOrderField(field);
    let tmpType = (field == orderField ? (orderType == 'ASC' ? 'DESC' : 'ASC') : 'ASC');
    setOrderType(tmpType);
    var params = {
      page: 1,
      search: {
        query: (query && query.length >= 3 ? query : ''),
        stato: selectedStatus ? selectedStatus : ''
      },
      order: {
        field: field ? field : 'id',
        type: tmpType
      }
    }
    getPage(1, params);
  }

  const handleAssignment = (evaluation_id) => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
            <h2>Conferma presa in carico</h2><br>
            <p>Vuoi confermare la presa in carico della<br>pratica con <b>ID ${evaluation_id}</b></p>
            `,
      width: '800px',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sì, lo confermo',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'No, non lo confermo',
      confirmButtonColor: '#12275E',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        // se ho confermato la presa in carico, faccio il retrieve della pratica perché devo vedere lo stato
        axios.post(window.SERVER_URL+'evaluations/get', { id: evaluation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          let tmp_eval = res.data.evaluation;
          
          axios.post(window.SERVER_URL+'evaluations/assign', { evaluation_id: evaluation_id, user_id: authobj.user.id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            if (res.data.success === true) {
              // se l'assegnazione è andata a buon fine
              setGlobalRefresh();
              Swal.fire({
                html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
                      <h2>OTTIMO LAVORO!</h2><br>
                      <p>La tua azione è andata a buon fine</p>
                      `,
                width: '800px',
                confirmButtonText: (tmp_eval.stato.slug == 'qualita' ? 'Inizia il controllo' : (tmp_eval.stato.slug == 'validazione' ? 'Inizia la validazione' : '')),
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonColor: '#12275E',
              }).then((result) => {
                if (result.isConfirmed) {
                  if (tmp_eval.stato.slug == 'qualita')
                    navigate('/evaluations/con', { state: { evaluation_id: evaluation_id } });
                  if (tmp_eval.stato.slug == 'validazione')
                    navigate('/evaluations/val', { state: { evaluation_id: evaluation_id } });
                }
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Errore',
                text: "Non è stato possibile prendere in carico la pratica. Potresti averne già una presa in carico manualmente."
              });
            }
          });
        });
      }
    });
  }

  const handleDelete = (evaluation_id) => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
            <h2>Conferma annullamento pratica</h2><br>
            <p>Vuoi davvero annullare la<br>pratica con <b>ID ${evaluation_id}</b></p>
            `,
      width: '800px',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sì, lo confermo',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'No, non lo confermo',
      confirmButtonColor: '#12275E',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'evaluations/delete', { id: evaluation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // se la cancellazione è andata a buon fine
            setEvaluations(evaluations.filter(evaluation => evaluation.id !== evaluation_id));
            // getPage(1, query);
            setGlobalRefresh();
            Swal.fire({
              html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
                    <h2>Pratica annullata</h2><br>
                    <p>La pratica è stata annullata correttamente</b></p>
                    `,
              width: '800px',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile annullare la pratica"
            });
          }
        });
      }
    });
  }

  const handleInvoicedPaid = (evaluation_id) => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
            <h2>Conferma avanzamento pratica</h2><br>
            <p>Vuoi confermare l'avanzamento della<br>pratica con <b>ID ${evaluation_id}</b></p>
            `,
      width: '800px',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sì, lo confermo',
      // denyButtonText: 'Annulla',
      cancelButtonText: 'No, non lo confermo',
      confirmButtonColor: '#12275E',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'evaluations/payment_cycle', { evaluation_id: evaluation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // se l'avanzamento di stato è andata a buon fine
            setGlobalRefresh();
            Swal.fire({
              html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png">
                    <h2>OTTIMO LAVORO!</h2><br>
                    <p>La tua azione è andata a buon fine</p>
                    `,
              width: '800px'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: "Non è stato possibile aggiornare lo stato della pratica."
            });
          }
        });
      }
    });
  }
  
  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className={ isWidget === true ? "content noMargin" : 'content' }>
        { isWidget !== true ?
          <Row className="page_title">
            <Col>
              <h1>Pratiche</h1>
            </Col>
          </Row>
        : '' }
        { ['superadmin','back-a'].includes(authobj.user.role.slug) ?
          <Row className="action_bar top">
            <Col>
              <Button variant="success" onClick={() => { navigate('/evaluations/add', {state: {page: page, query: query}}); }}><DynamicIcon iconName='BiPlus' /> Nuova pratica</Button>
            </Col>
          </Row>
        : '' }        
        <Row>
          <Col id="list_filter">
            <div>
              <form className="custom_form" id="query_form">
                <input type="text" placeholder="Cerca pratica" id="query" value={query} onChange={(e) => { handleInputChange(e.target.value) }} />
                <DynamicIcon iconName='FaSearch' />
              </form>
            </div>
            <div>
              <select value={selectedStatus} onChange={(e) => handleChangeSelectedStatus(e.target.value)}>
                <option value="">- Tutti gli stati -</option>
                { statuses ? statuses.map(s => 
                  <option key={ s.id } value={ s.id }>{ s.descrizione }</option>
                ) : '' }
              </select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='tab_wrapper'>
            { evaluations ? 
              <>
                <Table responsive className='eval_list'>
                  <thead>
                    <tr>
                      {/* <th onClick={() => { handleChangeOrder('id_commessa') }}>ID { orderField == 'id_commessa' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th> */}
                      <th onClick={() => { handleChangeOrder('id') }}>ID { orderField == 'id' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      { authobj.user.role.slug != "tec" ?
                        <th onClick={() => { handleChangeOrder('user.etichetta') }}>Cliente { orderField == 'user.etichetta' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      :
                        <th onClick={() => { handleChangeOrder('created_at') }}>Data caricamento { orderField == 'created_at' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      }
                      <th onClick={() => { handleChangeOrder('evaluation_type.descrizione') }}>Tipo { orderField == 'evaluation_type.descrizione' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      {/* <th onClick={() => { handleChangeOrder('sopralluogo_referente') }}>Referente { orderField == 'sopralluogo_referente' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th> */}
                      <th onClick={() => { handleChangeOrder('indirizzo.comune') }}>Comune { orderField == 'indirizzo.comune' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      <th onClick={() => { handleChangeOrder('indirizzo.provincia') }}>Prov { orderField == 'indirizzo.provincia' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                      { authobj.user.role.slug != "tec" ?
                        <>
                          <th onClick={() => { handleChangeOrder('titolare') }}>Titolare { orderField == 'titolare' ? <DynamicIcon iconName={ orderType == 'ASC' ? 'BiSolidUpArrow' : 'BiSolidDownArrow'} /> : '' }</th>
                          <th>{ ['qual-cont','qual-val'].includes(authobj.user.role.slug) ? 'Tecnico' : 'In carico a' }</th>
                        </>
                      : '' }
                      <th>Stato</th>
                      <th>Tag</th>
                      <th>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    { evaluations.length ? evaluations.map((evaluation) => {
                        return <tr key={ evaluation.id }>
                                {/* <td>{ evaluation ? evaluation.id_commessa : '-' }</td> */}
                                <td>{ evaluation ? evaluation.id : '-' }</td>
                                { authobj.user.role.slug != "tec" ?
                                  <td>{ evaluation && evaluation.user ? evaluation.user.etichetta : '-' }</td>
                                : 
                                  <td>{ evaluation ? evaluation.data_caricamento : '-' }</td>
                                }
                                <td>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '-' }</td>
                                {/* <td>{ evaluation ? evaluation.sopralluogo_referente : '-' }</td> */}
                                <td>{ evaluation && evaluation.town ? evaluation.town.nome : '-' }</td>
                                <td>{ evaluation && evaluation.province ? evaluation.province.sigla : '-' }</td>
                                { authobj.user.role.slug != "tec" ?
                                  <>
                                    <td>{ evaluation && evaluation.titolare ? evaluation.titolare : '-' }</td>
                                    { ['qual-cont','qual-val'].includes(authobj.user.role.slug) ? 
                                      <td>{ evaluation && evaluation.technician ? evaluation.technician.etichetta : '-' }</td>
                                    :
                                      <td>{ evaluation && evaluation.in_carico ? evaluation.in_carico.nome+' '+evaluation.in_carico.cognome : '-' }</td>
                                    }
                                  </>
                                : '' }
                                <td><span className='eval_state_label'>{ evaluation && evaluation.stato && evaluation.stato.descrizione ? evaluation.stato.descrizione : '-' }</span></td>
                                <td style={{'textAlign':'center'}}>
                                  { evaluation && evaluation.stato && evaluation.stato.descrizione && evaluation.stato.descrizione.toLowerCase() == 'sospesa' ?
                                    <div className='tag suspended'></div>
                                  : '' }
                                </td>
                                {/* <td>{ evaluation.created_at ? evaluation.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }</td> */}
                                <td className="actions" style={{'textAlign':'right','width': ['superadmin','back-a'].includes(authobj.user.role.slug) ? '260px' : '200px'}}>
                                  { evaluation.stato.slug != 'canc' && evaluation.azioni.length ?
                                    <>
                                      { evaluation.azioni.map((action) => (
                                        action.slug == 'overview' ?
                                          <Button onClick={() => { navigate('/evaluations/det', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={action.abilitato == '1' ? 'eval_recap' : 'eval_recap disabled'}>
                                            <DynamicIcon iconName='FaEye' />
                                            <Tooltip anchorSelect=".eval_recap" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'mod-pratica' ?
                                          <Button onClick={() => { navigate('/evaluations/add', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={action.abilitato == '1' ? 'eval_add' : 'eval_add disabled'}>
                                            <DynamicIcon iconName='FaPen' data-tooltip-id="eval_add" />
                                            <Tooltip anchorSelect=".eval_add" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'assegna-tec' ?
                                          <Button onClick={() => { navigate('/evaluations/ass', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={action.abilitato == '1' ? 'eval_tec' : 'eval_tec disabled'}>
                                            <DynamicIcon iconName='FaUser' data-tooltip-id="eval_tec" />
                                            <Tooltip anchorSelect=".eval_tec" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'gestisci-app' ?
                                          <Button onClick={() => { handleAppointment(evaluation.id) }} className={action.abilitato == '1' ? 'eval_app' : 'eval_app disabled'}>
                                            <DynamicIcon iconName='FaCalendarAlt' />
                                            <Tooltip anchorSelect=".eval_app" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'rendiconta' ?
                                          <Button onClick={() => { navigate('/evaluations/reg', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={action.abilitato == '1' ? 'eval_reg' : 'eval_reg disabled'}>
                                            <DynamicIcon iconName='BiSolidPencil' />
                                            <Tooltip anchorSelect=".eval_reg" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'prendi-carico' ?
                                          <Button onClick={() => { handleAssignment(evaluation.id) }} className={action.abilitato == '1' ? 'eval_ass' : 'eval_ass disabled'}>
                                            <DynamicIcon iconName='FaCheck' data-tooltip-id="eval_ass" />
                                            <Tooltip anchorSelect=".eval_ass" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'qualita-cont' ?
                                          <Button onClick={() => { navigate('/evaluations/con', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={action.abilitato == '1' ? 'eval_con' : 'eval_con disabled'}>
                                            <DynamicIcon iconName='MdOutlinePublishedWithChanges' size={20} data-tooltip-id="eval_con" />
                                            <Tooltip anchorSelect=".eval_con" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'qualita-val' ?
                                          <Button onClick={() => { navigate('/evaluations/val', { state: { evaluation_id: evaluation.id, page: page, query: query }}) }} className={action.abilitato == '1' ? 'eval_val' : 'eval_val disabled'}>
                                            <DynamicIcon iconName='FaThumbsUp' data-tooltip-id="eval_val" />
                                            <Tooltip anchorSelect=".eval_val" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'sospendi' ?
                                          (authobj.user.role.slug != 'qual-cont' || evaluation.quality_cont_id) && (authobj.user.role.slug != 'qual-val' || evaluation.quality_val_id) ?
                                            <Button onClick={() => { evaluation && evaluation.stato && evaluation.stato.slug && evaluation.stato.slug === 'sospesa' ? handleRework(evaluation.id) : handleSuspension(evaluation.id); }} className={action.abilitato == '1' ? 'eval_susp' : 'eval_susp disabled'}>
                                              <DynamicIcon iconName='FaHandPaper' />
                                              <Tooltip anchorSelect=".eval_susp" place="top" variant="info">{ action.descrizione }</Tooltip>
                                            </Button>
                                          : ''
                                        : action.slug == 'annulla' ?
                                          <Button onClick={() => { handleDelete(evaluation.id) }} className={action.abilitato == '1' ? 'eval_del' : 'eval_del disabled'}>
                                            <DynamicIcon iconName='FaTrash' />
                                            <Tooltip anchorSelect=".eval_del" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'gestisci' ?
                                          <Button onClick={() => { handleInvoicedPaid(evaluation.id) }} className={action.abilitato == '1' ? 'eval_pay' : 'eval_pay disabled'}>
                                            <DynamicIcon iconName='FaEuroSign' />
                                            <Tooltip anchorSelect=".eval_pay" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : action.slug == 'esporta-zip' ?
                                          <Button onClick={() => { handleExport(evaluation.id) }} className={action.abilitato == '1' ? 'eval_export' : 'eval_export disabled'}>
                                            <DynamicIcon iconName='FaDownload' />
                                            <Tooltip anchorSelect=".eval_export" place="top" variant="info">{ action.descrizione }</Tooltip>
                                          </Button>
                                        : ''
                                      ))}
                                    </>
                                  : '' }
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
                { numPages > 1 ?
                  <div className='pageListWrapper'>
                    <ul className='pagesList'>
                      { [...Array(numPages)].map(function(obj, i) {
                        let ii = i+1;
                        if (numPages > 5)
                          switch (parseInt(ii)) {
                            case 1:
                            case (page-1):
                            case page:
                            case page+1:
                            case numPages:
                              return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                            default:
                              return '';
                          }
                        else
                          return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                      }) }
                    </ul>
                  </div>
                : '' }
              </>
            : <p style={{textAlign:'center'}}>Nessuna pratica presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default Evaluations;